(function($){

	$(document).ready(function() {

		/* ---------------------------------------------- /*
		 * Contact form ajax
		 /* ---------------------------------------------- */

		$('#contact-form').validator().on('submit', function (e) {
			if (e.isDefaultPrevented()) {

				// handle the invalid form...

			} else {

				e.preventDefault();

				var form            = $('#contact-form');

				var submit          = $('#contact-submit-button');
				var ajaxResponse    = $('#contact-response');
				var submittext      = submit.html();

				var formdata = form.serializeArray();
				formdata.push({name: 'rcresponse', value: grecaptcha.getResponse()});


				$.ajax({
					type: 'POST',
					url: '/cms/ajax/contact.php',
					dataType: 'json',
					data: formdata,
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					},
					error: function(result) {
						alert(JSON.stringify(result));
					},
					success: function(result) {

						if(result.sendstatus == 1) {
							ajaxResponse.css('padding-top', '25px');
							ajaxResponse.css('margin-bottom', '300px');
							ajaxResponse.css('color', '#C8004B');
							ajaxResponse.css('background-color', '#f0f5fa');
							ajaxResponse.css('min-height', '80px');
							ajaxResponse.html(result.message);
							form.fadeOut(500);
							$('html,body').animate({
								scrollTop: $("#contact-response").offset().top - 200
							});
						} else {
							ajaxResponse.css('color', '#C8004B');
							ajaxResponse.html(result.message);
							submit.empty();
							submit.append(submittext);
						}
					}
				});
			}
		});

		$('#callme-form').validator().on('submit', function (e) {
			if (e.isDefaultPrevented()) {

				// handle the invalid form...

			} else {

				e.preventDefault();

				var form            = $('#callme-form');

				var submit          = $('#callme-submit-button');
				var ajaxResponse    = $('#callme-response');

				var formdata = form.serializeArray();
				formdata.push({name: 'rcresponse', value: grecaptcha.getResponse()});


				/*
				var name            = $('#callme-form [name="name"]').val();
				var telephone       = $('#callme-form [name="telephone"]').val();
				var ipaddress       = $('#callme-form [name="ipaddress"]').val();
				*/

				var submittext      = submit.html();

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/callme.php',
					dataType: 'json',
					data: formdata,
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.css('color', '#444');
							ajaxResponse.html(result.message);
							form.fadeOut(500);
						} else {
							ajaxResponse.css('color', '#C8004B');
							ajaxResponse.html(result.message);
							submit.empty();
							submit.append(submittext);
						}
					}
				});
			}
		});


		$('#download-form').validator().on('submit', function (e) {
			if (e.isDefaultPrevented()) {

				// handle the invalid form...

			} else {

				e.preventDefault();

				var form            = $('#download-form');

				var submit          = $('#download-form submit');
				var ajaxResponse    = $('#download-response');

				/*
				var name            = $('#download-form [name="name"]').val();
				var email           = $('#download-form [name="email"]').val();
				*/

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/download.php',
					dataType: 'json',
					data: form.serialize(),
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.html(result.message);
							form.fadeOut(500);
						} else {
							ajaxResponse.html(result.message);
						}
					}
				});
			}
		});



	});

})(jQuery);